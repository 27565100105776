@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
}

.Kalend__main, .Kalend__main * {
  font-family: 'Open Sans', sans-serif !important;
}

input {
  font-family: 'Open Sans', sans-serif !important;
}

label {
  text-transform: none !important;
  font-size: inherit !important;
  font-weight: inherit !important;
}

*:focus, *:focus-visible, *:focus-within {
  box-shadow: none !important;
  outline: none !important;
  border-color: #00a79d !important;
}

a, a:hover, a:visited {
  color: #00a79d;
}

.p-inline-message .p-inline-message-text {
  text-align: left !important;
}

.p-inline-message.p-inline-message-warn {
  background: #f6a415 !important;
  color: #fff !important;
  text-align: left !important;
}

.p-button, button.p-button, button.p-button:hover {
  background-color: #00a79d !important;
  border-color: #00a79d !important;
}

button.p-button.p-button-outlined, .p-button-outlined:hover {
  border-color: #00a79d;
  color: #00a79d !important;
  background: transparent !important;
}

.p-button.p-button-success, button.p-button.p-button-success {
  background-color: #00a79d !important;
  border-color: #00a79d !important;
}

.p-button.p-button-warning, button.p-button.p-button-warning {
  background-color: #f6a415 !important;
  border-color: #f6a415 !important;
}

.p-button.p-button-danger, button.p-button.p-button-danger {
  background-color: #64748a !important;
  border-color: #64748a !important;
  color: #fff !important;
}

.p-button.p-button-secondary, button.p-button.p-button-secondary {
  background-color: #64748a !important;
  border-color: #64748a !important;
}

.p-checkbox {

}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #00a79d !important;
  background: #00a79d !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #00a79d !important;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #00a79d !important;
}

.p-inputtext:enabled:hover {
  border-color: #00a79d !important;
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 15px !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #00a79d !important;
}

.p-dropdown {
  padding: 2px !important;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #00a79d !important;
  box-shadow: none !important;
}

.transparent {
  background: transparent !important;
}

.p-tabview .p-tabview-nav-content ul li.p-tabview-selected a {
  border-bottom: 3px solid #00a79d !important;
  color: #00a79d !important;
}

.p-tabview .p-tabview-nav {
  background: transparent !important;
  border: 0 !important
}

table tr, table th, table td, .p-paginator {
  background: transparent !important;
}

table .p-button-icon-only {
  border-radius: 100% !important;
  padding: 0.5375rem 0.875rem !important;
}

table tr.p-datatable-row-expansion td div.p-ml-3 .p-button-success {
  padding: 0;
  border: 0;
  background: none !important;
  color: #00a79d !important;;
}

table tr:nth-child(odd).p-datatable-row-expansion td div.p-ml-3 .p-paginator {
  background: #fff !important;
  border-color: #fff !important;
}


.p-datatable .p-sortable-column.p-highlight, .p-datatable .p-sortable-column.p-highlight * {
  color: #00a79d !important;
}

.p-menubar .p-menubar-start img {
  max-width: none !important;
  margin-bottom: -5px;
  margin-left: -10px;
  width: auto !important;
  height: 52px !important;
}

.p-menubar .activeMenu {
  border-bottom: 2px solid #00a79d !important;
}

.p-menubar .activeMenu * {
  font-weight: bold;
  color: #00a79d !important;
}

.p-dialog-content {
  padding-top: 2rem !important;
}

.p-panel .p-panel-header .p-panel-title {
  color: #00a79d;
}

body.outside {
}

.LoginPage {
  background: transparent !important;
}

.LoginPage img {
  max-width: 200px;
}

.LoginPage .p-panel {
  background: #fff;
  padding: 30px !important;
  border-radius: 15px;
}

.LoginPage .p-text-center a.p-text-light {
  color: #00a79d !important;
}

.DashboardPage .right {
  background-color: #00a79d;
  background-image: linear-gradient(#00a79d, #17a2b8);
}

.ModuleDashboardPage .right {
  background-color: #00a79d;
  background-image: linear-gradient(#00a79d, #17a2b8);

}

.right a.w-100:hover .p-card {
  background: #5d739a;
  color: #fff;
}

.right button.p-button-outlined {
  color: #fff !important;
  border-color: #fff !important;
}

.text-primary {
  color: #00a79d;
}

.EntryzoneLiveList .p-panel.status-PASS, .EntryzoneLiveList .p-panel.status-PASS * {
  background: #00a79d;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-UNID, .EntryzoneLiveList .p-panel.status-UNID * {
  background: #f6a415;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-BLCK, .EntryzoneLiveList .p-panel.status-BLCK * {
  background: #f31a26;
  border: 0;
  color: #fff;
}

.EntryzoneLiveList .p-panel.status-STORNO, .EntryzoneLiveList .p-panel.status-STORNO * {
  background: #6c757d;
  border: 0;
  color: #fff;
}

/* layout mobile overrides */

.p-menubar .p-menubar-button {
  margin-top: -48px;
  float: right !important;
}
